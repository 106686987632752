<template>
  <div v-if="userType == 101" class="spatial-retrieval">
      <module-box :appModule="appModule">
            <div class="select" slot="search">
                <!-- <div class="more-wrapper">更多</div> -->
            </div>
            <el-breadcrumb  v-if="queryGardenBuildingList.length"  class="breadcrumb" separator="/">
                <el-breadcrumb-item @click.native="toCurrent(0)"><a href="javascript:;">{{gardenName || headerDefault[0]}}</a></el-breadcrumb-item>
                <el-breadcrumb-item @click.native="toCurrent(1)" v-if="isCurrent>0"><a href="javascript:;">{{buildingName || headerDefault[1]}}</a></el-breadcrumb-item>
                <el-breadcrumb-item @click.native="toCurrent(2)" v-if="isCurrent>1"><a href="javascript:;">{{unitName || headerDefault[2]}}</a></el-breadcrumb-item>
                <el-breadcrumb-item @click.native="toCurrent(3)" v-if="isCurrent===3"><a href="javascript:;">{{roomName|| headerDefault[3]}}</a></el-breadcrumb-item>
            </el-breadcrumb>
            <el-empty v-else></el-empty>

            <!-- 选择苑 -->
            <div v-if="isCurrent === 0" class="change">
                <div class="item" @click="linkTo(0,item)"  v-for="(item,index) in gardenList" :key="index">
                    <span>{{item?item:'#'}}</span>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <!-- 选择幢 -->
            <div v-if="isCurrent === 1" class="change">
                <div class="item" @click="linkTo(1,item)" v-for="(item,index) in buildingList" :key="item.roomId">
                    <span>{{item.buildingNumber}}</span>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <!-- 选择单元 -->
            <div v-if="isCurrent === 2" class="change">
                <div class="item" @click="linkTo(2,item)" v-for="(item,index) in unitList" :key="index">
                    <span>{{item?item:'#'}}</span>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <!-- 选择房号 -->
            <div v-if="isCurrent === 3" class="change">
                <div class="item" @click="linkTo(3,item)" v-for="(item,index) in roomList" :key="item.roomId">
                    <span>{{item.room?item.room:'#'}}</span>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
      </module-box>    
  </div>
</template>

<script>
import ModuleBox from '../ModuleBox'
import {Breadcrumb,BreadcrumbItem,Empty} from 'element-ui'
import {queryGardenBuildingURL,queryUnitRoomURL} from './api'
export default {
    name:'spatialRetrieval',
    components:{
        ModuleBox,
        "el-breadcrumb":Breadcrumb,
        "el-breadcrumb-item":BreadcrumbItem,
        "el-empty":Empty
    },
    props: {
        appModule: {
            type: Object,
            default () {
                    return {}
            }
        }
    },
    data(){
       return{
           title: '平台公告',
           isActive:0,
           gardenName:'',
           isCurrent:0,
           
           buildingName:"",
           unitName:"",
           roomName:"",
           headerDefault: ['请选择苑', '请选择幢', '请选择单元', '请选择房号'],
           queryGardenBuildingList:[],
           queryUnitRoomList:[],
           gardenList:[],
           buildingList:[],
           unitList:[],
           roomList:[],
           userType:''
       }
    },
    mounted(){
        this.userType = this.$store.state.userInfo.userType
        if(this.userType == 101){
            this.getQueryGardenBuilding()
        }
        
    },
    methods:{
        linkTo(index,gardenItem){
            if(index === 0){
                this.isCurrent += 1
                this.gardenName = gardenItem?gardenItem:'#'
                this.buildingList = this.queryGardenBuildingList.filter(item=>item.blockName === gardenItem)
            }
            if(index === 1){
                this.isCurrent += 1
                this.buildingName = gardenItem.buildingNumber
                this.getQueryUnitRoom(gardenItem)
            }
            if(index === 2){
                this.isCurrent += 1
                this.unitName = gardenItem?gardenItem:'#'
                this.roomList = this.queryUnitRoomList.filter(item=>item.unit === gardenItem)
            }
            if(index === 3){
                this.roomName = gardenItem.room
                this.$router.push({
                    path:'/home/houseInfo',
                    query:{
                        roomId:gardenItem.roomId
                    }
                })
            }
            
        },
        // 点击面包屑改变名称
        toCurrent(index){
            this.isCurrent = index;
            if(this.isCurrent === 0){
                this.gardenName = ''
                this.buildingName = ''
                this.unitName = ''
                this.roomName = ''
            }
            if(this.isCurrent === 1){
                this.buildingName = ''
                this.unitName = ''
                this.roomName = ''
            }
            if(this.isCurrent === 2){
                this.unitName = ''
                this.roomName = ''
            }
            if(this.isCurrent === 3){
                this.roomName = ''
            }
        },
        getQueryGardenBuilding(){
            this.$axios.get(queryGardenBuildingURL).then(res=>{
                if(res.status === 100){
                    let arr = []
                    res.data.forEach(item=>{
                        arr.push(item.blockName)
                    })
                    this.gardenList =  new Set(arr)
                    this.queryGardenBuildingList = res.data
                }
            })
        },
        getQueryUnitRoom(gardenItem){
            this.$axios.get(queryUnitRoomURL,{
                params:{
                    blockName:gardenItem.blockName,
                    buildingNumber:gardenItem.buildingNumber
                }
            }).then(res=>{
                if(res.status === 100){
                    let arr = []
                    res.data.forEach(item=>{
                        arr.push(item.unit)
                    })
                    this.unitList =  new Set(arr)
                    this.queryUnitRoomList = res.data
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.spatial-retrieval{
    min-height: 600px;
    // background-color: #fff;
}
.select{
    display: inline-block;
}
::v-deep .el-breadcrumb{
    padding-left: 18px;
    .el-breadcrumb__item{
        .el-breadcrumb__inner{
            color: #909399;
        }
    }
}

.change{
    padding: 18px;
    color: #323233;
    line-height: 30px;
    .el-icon-arrow-right{
        line-height: 30px;
    }
}
.item{
        display: flex;
        justify-content: space-between;
        padding: 5px;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
            // background: #5A8DEE;
            background: #E4EFFF;
            // color: #fff;
            color: #2077FF;
            // box-shadow: 0px 2px 16px 0px rgba(27, 140, 242, 0.37);
        }
}
::v-deep .el-breadcrumb__inner{
    a{
        font-weight: 400;
        font-size: 12px;
        // line-height: 20px;
        font-family: 'PingFang SC';
        color: #909399;
        &:hover{
            color: #5A8DEE;
        }
    }
}
</style>