// http://172.16.11.195:7020/manage
// 获取苑幢信息
// const queryGardenBuildingURL = `http://172.16.11.195:7020/manage/workbench/queryGardenBuilding`
const queryGardenBuildingURL = `${API_CONFIG.butlerBaseURL}workbench/queryGardenBuilding`
// 获取房号信息
// const queryUnitRoomURL = `http://172.16.11.195:7020/manage/workbench/queryUnitRoom`
const queryUnitRoomURL = `${API_CONFIG.butlerBaseURL}workbench/queryUnitRoom`

export {
    queryGardenBuildingURL,
    queryUnitRoomURL
}